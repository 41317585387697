import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import ContractsUpdateFromOmgFeedbackCsvTask from './ContractsUpdateFromOmgFeedbackCsvTask';
import { Button, List } from 'antd';
import { Loading } from '../helpers';

const ContractsUpdateFromOmgFeedbackCsvTasksQuery = graphql`
  query ContractsUpdateFromOmgFeedbackCsvTasksQuery {
    contractsUpdateFromOmgFeedbackCsvTasks {
      id
      startDate
      endDate
      date
      status
      filename
      totalToProcess
      totalProcessed
    }
  }
`;

const useReload = () => {
  const [reloadIndex, setReloadIndex] = useState(0);

  const onReload = () => {
    setReloadIndex(reloadIndex + 1 );
  };

  return [
    reloadIndex,
    onReload
  ];
};

const ContractsUpdateFromOmgFeedbackCsvTasks = () => {
  const [reloadIndex, onReload] = useReload();

  return <div style={{ marginTop: 8 }}>
    <Button size="small" shape="circle" icon="sync" onClick={onReload}/>

    <br/>

    <QueryRenderer
      key={reloadIndex}
      environment={environment}
      variables={{}}
      query={ContractsUpdateFromOmgFeedbackCsvTasksQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.stack}</div>;
        } else if (!props) {
          return <Loading/>;
        }

        const tasks = R.pathOr([], ['contractsUpdateFromOmgFeedbackCsvTasks'])(props);

        return (
          <List
            dataSource={tasks}
            renderItem={item => (
              <List.Item>
                <ContractsUpdateFromOmgFeedbackCsvTask
                  task={item}
                  onRefreshTasks={onReload}
                />
              </List.Item>
            )}
          />
        );
      }}
    />
  </div>;
};

export default ContractsUpdateFromOmgFeedbackCsvTasks;
