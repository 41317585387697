import * as R from 'ramda';
import Moment from 'moment';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Row, Col } from 'antd';
import RetryUpdateContractFromOMGMutation from '../../mutations/RetryUpdateContractFromOMGMutation';

const useIntervalCallback = (interval, callback) => {
  const [running, setRunning] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (running) {
      ref.current = setInterval(
        () => {
          callback();
        },
        interval
      );
      return (() => clearInterval(ref.current));
    } else {
      clearInterval(ref.current);
    }
  }, [running]);

  const startIntervalCallback = () => {
    setRunning(true);
  };

  const stopIntervalCallback = () => {
    setRunning(false);
  };

  return {
    startIntervalCallback,
    stopIntervalCallback
  };
};

const Task = ({ value, onRefreshTasks }) => {

  const [cachedValue, setCachedValue] = useState(value);

  const {
    startIntervalCallback,
    stopIntervalCallback
  } = useIntervalCallback(1000, onRefreshTasks);

  useEffect(() => {
    if (!R.isNil(value)) {
      setCachedValue(value);
      if (value.status === 'processing') startIntervalCallback(); else stopIntervalCallback();
    }
  }, [value]);

  const onRetryTask = useCallback(
    () => {
      RetryUpdateContractFromOMGMutation(cachedValue.id, () => {
        onRefreshTasks();
      });
    }
  );

  const showDate = date => !R.isNil(date) ? Moment(date).format('DD/MM/YYYY - HH:mm:ss') : '';
  if (R.isNil(cachedValue)) return null;

  const {
    date,
    startDate,
    endDate,
    status,
    filename,
    totalProcessed,
    totalToProcess
  } = cachedValue;

  return <Row style={{ width: '100%', fontSize: 12 }}>
    <Col span={4}>
      {showDate(date)}
    </Col>

    <Col span={4}>
      {showDate(startDate)}
    </Col>

    <Col span={4}>
      {showDate(endDate)}
    </Col>

    <Col span={2} style={{ textAlign: 'center', color: status === 'failed' ? 'red' : '' }}>
      {`${status} (${totalProcessed}/${totalToProcess})`}
    </Col>

    <Col span={7} style={{ textAlign: 'center' }}>
      {filename}
    </Col>

    <Col span={3} style={{ textAlign: 'center' }}>
      {status !== 'done' && (
        <Button onClick={onRetryTask} size="small">
          {'Relancer'}
        </Button>
      )}
    </Col>
  </Row>;
};

const ContractsUpdateFromOmgFeedbackCsvTask = ({ task, onRefreshTasks }) => {
  return <Task
    value={task}
    onRefreshTasks={onRefreshTasks}
  />;
};

export default ContractsUpdateFromOmgFeedbackCsvTask;
